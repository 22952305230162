exports.components = {
  "component---node-modules-jaenjs-jaen-src-internal-plugins-pages-internal-services-routing-pages-tsx": () => import("./../../../node_modules/@jaenjs/jaen/src/internal-plugins/pages/internal/services/routing/pages/_.tsx" /* webpackChunkName: "component---node-modules-jaenjs-jaen-src-internal-plugins-pages-internal-services-routing-pages-tsx" */),
  "component---node-modules-jaenjs-jaen-src-ui-admin-page-tsx": () => import("./../../../node_modules/@jaenjs/jaen/src/ui/AdminPage.tsx" /* webpackChunkName: "component---node-modules-jaenjs-jaen-src-ui-admin-page-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */),
  "component---src-template-pages-category-page-index-tsx": () => import("./../../../src/templatePages/CategoryPage/index.tsx" /* webpackChunkName: "component---src-template-pages-category-page-index-tsx" */),
  "component---src-template-pages-product-page-index-tsx": () => import("./../../../src/templatePages/ProductPage/index.tsx" /* webpackChunkName: "component---src-template-pages-product-page-index-tsx" */),
  "component---src-template-pages-shop-page-index-tsx": () => import("./../../../src/templatePages/ShopPage/index.tsx" /* webpackChunkName: "component---src-template-pages-shop-page-index-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/NewsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */)
}


module.exports = [{
      plugin: require('../node_modules/@jaenjs/jaen/src/internal-plugins/pages/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@jaenjs/jaen/src/internal-plugins/notify/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@jaenjs/jaen/gatsby-browser.tsx'),
      options: {"plugins":[],"jaenProjectId":3},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
